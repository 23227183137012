const companyDataConfig: CompanyDataConfig<string> = {
    address: 'Riverside Avenue 5a m. 50, 04-432 Warsaw',
    phone: '+48 123 456 789',
    email: 'info@realestate.com'
}

export interface CompanyDataConfig<T> {
    address: T;
    phone: T;
    email: T;
}

export default companyDataConfig;