import {createGlobalStyle} from 'styled-components';

import {Normalize} from './normalize.types';

export default createGlobalStyle<Normalize>`
  * {
    box-sizing: border-box;
  }

  html {
    overflow-y: scroll;
    font-size: 16px;
    line-height: 20px;
    font-family: ${props => props.theme.fonts.primary};
  }

  body {
    font-size: 1rem;
    line-height: 1.25;
  }

  html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, canvas, embed, footer, header, nav, section, video {
    margin: 0;
    padding: 0;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  main {
    position: relative;
    flex-grow: 2;
  }

  section {
    height: 100%;
  }

  a, a:visited {
    text-decoration-line: none;
    color: ${props => props.theme.colors.white};
  }

  ol, ul {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
  }

  button {
    margin: 0;
    padding-block: 0;
    padding-inline: 0;
    border-width: 0;
    background: transparent;
  }
`;
