import {Action, configureStore, ThunkDispatch} from '@reduxjs/toolkit';
import {ToolkitStore} from '@reduxjs/toolkit/dist/configureStore';

import rootReducer, {RootState} from './root-reducer';

export const store: ToolkitStore<RootState> = configureStore({
    reducer: rootReducer,
});

export type AppDispatch = ThunkDispatch<RootState, null, Action>;
