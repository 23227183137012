import {css} from 'styled-components';

import {BackgroundImageProps, BoxConfig} from './common.types';
import {FormElementCommon} from '../../components/common/form/form.types';

export const backgroundImageCommon = css<BackgroundImageProps>`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${({image}) => image});
  background-position: 0 0, 0 0;
  background-size: auto, cover;
`
export const boxConfigCommon = css<BoxConfig>`
  ${({margin}) => margin ? `
    ${margin.bottom !== undefined ? `margin-bottom: ${margin.bottom};` : ''}
    ${margin.top !== undefined ? `margin-top: ${margin.top};` : ''}
    ${margin.left !== undefined ? `margin-left: ${margin.left};` : ''}
    ${margin.right !== undefined ? `margin-right: ${margin.right};` : ''}
  ` : ''
  }
  ${({padding}) => padding ? `
    ${padding.bottom !== undefined ? `padding-bottom: ${padding.bottom};` : ''}
    ${padding.top !== undefined ? `padding-top: ${padding.top};` : ''}
    ${padding.left !== undefined ? `padding-left: ${padding.left};` : ''}
    ${padding.right !== undefined ? `padding-right: ${padding.right};` : ''}
  ` : ''
  }
`

export const formStyledCommon = css<FormElementCommon>`
  ${props => props.size === 'small' ?
          ` 
    height: 32px;
    padding: 5px 10px 5px 15px;
   ` :
          `
    height: 50px;
    padding: 10px 15px 10px 15px;
  `}

  ${props => `
    color: ${props.theme.colors.grayDark};
    border: 1px solid ${props.theme.colors.grayMedium};
    ${props.width ? `width: ${props.width}` : ''};
    background-color: ${props.disabled ? props.theme.colors.grayLight : '#fff'};
  `};

  margin-bottom: 0;
  border-radius: 5px;

`

export const skeletonCommon = css`
  background: ${({theme}) => theme.colors.grayMedium};
`