import {useEffect, useState} from 'react';

import {Breakpoints} from '../style/normalize.types';
import {breakpoints} from '../style/theme';

const useBreakpoint = (breakpointToCheck?: keyof Breakpoints) => {
    const [breakpoint, setBreakpoint] = useState<keyof typeof breakpoints | null>(null);
    const [breakpointCheck, setBreakpointCheck] = useState(false);

    useEffect(() => {
        if (breakpointToCheck && breakpoint) {
            setBreakpointCheck(breakpoints[breakpoint] <= breakpoints[breakpointToCheck])
        }
    }, [breakpointToCheck, breakpoint])


    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;

            for (const key in breakpoints) {
                const convertKey = key as keyof Breakpoints;

                if (width >= breakpoints[convertKey]) {
                    setBreakpoint(convertKey);
                    break;
                }
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {breakpoint, breakpointCheck};
};

export default useBreakpoint;