import styled, {keyframes} from 'styled-components';

import {NavbarMobileStyledProps} from './navbar-mobile.types';

const moveDown = keyframes`
  0% {
    top: -675px;
  }
  100% {
    top: 0px;
  }
`

const moveUp = keyframes`
  0% {
    top: 0px;
  }
  100% {
    top: -675px;
  }
`

export const NavbarMobileStyled = styled.nav<NavbarMobileStyledProps>`
  position: relative;
  z-index: 1001;
  animation-name: ${({$open}) => $open ? moveDown : moveUp};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  a.active span {
    color: ${({theme}) => theme.colors.blueLight};
  }
`

export const NavbarElement = styled.div`
  padding: 0 24px;
  width: 100%;
  text-align: center;
  ${({theme}) => `
    background: ${theme.colors.white};
  `};
`