import {createSlice, Slice} from '@reduxjs/toolkit';

import {NewsResponse} from './news-slice.types';
import {initializePropertiesState} from './news-slice.helper';

export const newsSlice: Slice<NewsResponse> = createSlice({
    name: 'news',
    initialState: {
        ...initializePropertiesState()
    } as NewsResponse,
    reducers: {
        addNews: (state, action) => {
            state.data = [...action.payload];
        }
    },
});

export const {addNews} = newsSlice.actions;
