import styled, {css} from 'styled-components';

import {ContainerStyledProps} from './container.types';
import {boxConfigCommon} from '../../../style/common/common.styled';
import {Colors} from '../../../style/normalize.types';

const containerCommon = css<ContainerStyledProps>`
  ${({shadow}) => shadow ? `box-shadow: ${shadow};` : ''}
  ${({width}) => width ? `width: ${width};` : ''}
  ${({theme, background}) => `
     background: ${theme.colors[background as keyof Colors] ?? 'transparent'};`}
  ${({coords, position, index}) => coords && position !== 'static' ? `
    position: ${position};
    z-index: ${index};
    ${coords.bottom !== undefined ? `bottom: ${coords.bottom};` : ''}
    ${coords.top !== undefined ? `top: ${coords.top};` : ''}
    ${coords.left !== undefined ? `left: ${coords.left};` : ''}
    ${coords.right !== undefined ? `right: ${coords.right};` : ''}
  ` : `position: ${position};`
  }
  ${boxConfigCommon}
`;

export const ContainerFooter = styled.footer<ContainerStyledProps>`
  ${containerCommon}
`;

export const ContainerSection = styled.section<ContainerStyledProps>`
  ${containerCommon}
`;

export const ContainerDiv = styled.div<ContainerStyledProps>`
  ${containerCommon}
`;

export const ContainerInside = styled.div`
  max-width: 1248px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
`
