import {Breakpoints, Theme} from './normalize.types';

export const breakpoints: Breakpoints = {
    desktopUp: 1920,
    desktopLarge: 1440,
    desktopSmall: 991,
    tablet: 767,
    mobileLarge: 479,
    mobileSmall: 320,
}

export const theme: Theme = {
    colors: {
        black: '#000000',
        white: '#FFFFFF',
        blueDark: '#0082f3',
        blueLight: '#25a9e0',
        grayDark: '#777777',
        grayMedium: '#e0e0e0',
        grayLight: '#f7f7f7',
        red: '#ff0606'
    },
    breakpoints: breakpoints,
    fonts: {
        primary: '\'Arimo\', sans-serif',
        secondary: '\'Raleway\', sans-serif',
    }
};
