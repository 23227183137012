import styled from 'styled-components';

import {NavbarCommonProps} from './navbar-common.types';

export const NavbarCommonStyled = styled.div<NavbarCommonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: calc(100% + 48px);
  left: -24px;
  padding: 0 36px;
  background: ${({theme}) => theme.colors.white};
  ${({type, theme}) => type === 'static' ? `
    outline: 2px solid ${theme.colors.blueLight};
    outline-offset: 12px;
    height: 90px;
  ` : `
    height: 80px;
    
  `}
`
export const NavbarStaticImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
  }
`

export const NavbarStaticLinks = styled.nav`
  display: flex;
  gap: 28px;
  align-items: center;

  a.active span {
    color: ${({theme}) => theme.colors.blueLight};
  }

  a:hover span {
    color: ${({theme}) => theme.colors.blueLight};
    opacity: 0.4;
  }
`