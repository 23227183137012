import {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {NavbarElement, NavbarMobileStyled} from './navbar-mobile.styled';
import {NavbarMobileProps} from './navbar-mobile.types';
import TextLink from '../../navbar-static/text-link/text-link';
import Button from '../../../../common/button/button';
import linksPageConfig from '../../../../../config/links-page-config';
import {InternalLinkOrNull} from '../../../../../utils/internal-links/internal-links.types';

const NavbarMobile: FC<NavbarMobileProps> = ({open, handleModalCloseClick}) => {
    const navigate = useNavigate();

    const handleNavElementClick = (link: InternalLinkOrNull) => {
        if (link) {
            handleModalCloseClick();
            navigate(link);
        }
    }

    return (<NavbarMobileStyled $open={open}>
        {
            linksPageConfig.map(({link, title, isSpecial}) =>
                isSpecial ? <Button key={title} full={true}
                                    onClick={() => handleNavElementClick(link)}>{title}</Button> : link ?
                    <NavbarElement key={title}>
                        <button key={title} onClick={() => handleNavElementClick(link)}><TextLink
                            type={'static'}>{title}</TextLink></button>
                    </NavbarElement>
                    : <NavbarElement key={title}><TextLink type={'static'}>{title}</TextLink></NavbarElement>
            )
        }
    </NavbarMobileStyled>)
}

export default NavbarMobile;