import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {Provider as ProviderReactRedux} from 'react-redux';
import {Provider as ProviderUrql} from 'urql';

import Header from './header/header';
import Router from './layout/router/router';
import clientUrql from '../../config/graphql-config';
import GlobalStyle from '../../style/normalize';
import {theme} from '../../style/theme';
import {store} from '../../store/configure-store';
const App = () => {

    return (
        <BrowserRouter>
            <ProviderUrql value={clientUrql}>
                <ProviderReactRedux store={store}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle theme={theme}/>
                        <Header/>
                        <Router/>
                    </ThemeProvider>
                </ProviderReactRedux>
            </ProviderUrql>
        </BrowserRouter>
    )
}
export default App;