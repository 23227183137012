import {createSlice, Slice} from '@reduxjs/toolkit';

import {initializeAgentsState} from './agents-slice.helper';
import {AgentsResponse} from './agents-slice.types';

export const agentsSlice: Slice<AgentsResponse> = createSlice({
    name: 'agents',
    initialState: {
        ...initializeAgentsState()
    } as AgentsResponse,
    reducers: {
        addAgents: (state, action) => {
            state.data = [...action.payload];
        },
    },
});

export const {addAgents} = agentsSlice.actions;
