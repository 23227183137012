import styled, {css} from 'styled-components';

import {TextStyledCommon} from './text.types';
import {boxConfigCommon} from '../../../style/common/common.styled';
import {Colors, Fonts} from '../../../style/normalize.types';

export const textCommon = css<TextStyledCommon>`
  ${({theme, color, font, weight, $textStyle, size, $underline, $textTransform}) => `
    color: ${theme.colors[color as keyof Colors]};
    font-family: ${theme.fonts[font as keyof Fonts]};
    font-weight: ${weight};
    font-style: ${$textStyle};
    font-size: ${size}rem;
    text-decoration: ${$underline ? 'underline' : 'none'};
    text-transform: ${$textTransform};
  `};
  ${boxConfigCommon}
`

export const TextParagraph = styled.p<TextStyledCommon>`
  ${textCommon}
`;

export const TextSpan = styled.span<TextStyledCommon>`
  ${textCommon}
`;

export const TextH1 = styled.h1<TextStyledCommon>`
  ${textCommon}
`;

export const TextH2 = styled.h2<TextStyledCommon>`
  ${textCommon}
`;

export const TextH3 = styled.h3<TextStyledCommon>`
  ${textCommon}
`;