import {FC} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';

import {NavbarCommonStyled, NavbarStaticImageContainer, NavbarStaticLinks} from './navbar-common.styled';
import {NavbarCommonProps} from './navbar-common.types';
import TextLink from '../navbar-static/text-link/text-link';
import Button from '../../../common/button/button';
import logo from '../../../../assets/img/logo.png';
import linksPageConfig from '../../../../config/links-page-config';

const NavbarCommon: FC<NavbarCommonProps> = ({type}) => {
    const navigate = useNavigate();

    return (<NavbarCommonStyled type={type}>
        <NavbarStaticImageContainer>
            <img src={logo} alt={'logo'}/>
        </NavbarStaticImageContainer>
        <NavbarStaticLinks>
            {
                linksPageConfig.map(({link, title, isSpecial}) =>
                    isSpecial ? <Button key={title} onClick={() => link && navigate(link)}>{title}</Button> : link ?
                        <NavLink key={title} to={link}><TextLink type={type}>{title}</TextLink></NavLink>
                        : <TextLink key={title} type={type}>{title}</TextLink>
                )
            }
        </NavbarStaticLinks>
    </NavbarCommonStyled>)
}

export default NavbarCommon;