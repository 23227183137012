import {FC} from 'react';

import {ContainerDiv, ContainerFooter, ContainerInside, ContainerSection} from './container.styled';
import {ContainerProps} from './container.types';

const Container: FC<ContainerProps> = ({
                                           children,
                                           typeElement = 'div',
                                           background = 'white',
                                           position = 'static',
                                           ...rest
                                       }) => {
    const restProps = {background, position, ...rest};

    const insideElement = <ContainerInside>{children}</ContainerInside>;

    switch (typeElement) {
        case 'section':
            return <ContainerSection {...restProps}>{insideElement}</ContainerSection>
        case 'footer':
            return <ContainerFooter {...restProps}>{insideElement}</ContainerFooter>
        default:
            return <ContainerDiv {...restProps}>{insideElement}</ContainerDiv>
    }
}

export default Container;