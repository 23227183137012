import {PropertyType} from '../properties.types';

export enum PropertiesSortDirection {
    ASC = 'ASC',
    DESC = 'DESC'
}

export type PaginationButtonStatus = 'active' | 'not-active' | 'arrow-active' | 'arrow-not-active'

export interface PaginationButtonStyledProps {
    status: PaginationButtonStatus;
}

export type PropertiesSortBy = 'surface' | 'price';

export interface PropertiesPaginationParams {
    limit: number;
    maxPages: number;
    page: number;
    sortBy: PropertiesSortBy;
    sortDirection: PropertiesSortDirection;
}

export interface FilterRangeElement {
    min: number;
    max: number;
}

export interface PropertiesFilter {
    type: PropertyType | null,
    cities: string[],
    price: FilterRangeElement,
    surface: FilterRangeElement
}
