import {PropsWithChildren} from 'react';

import {BoxConfig} from '../../../style/common/common.types';
import {Colors, Fonts} from '../../../style/normalize.types';

type TypeElement = 'p' | 'span' | 'h1' | 'h2' | 'h3';

interface TextCommon {
    color?: keyof Colors;
    font?: keyof Fonts;
    size?: TextSize;
    weight?: TextWeight;
}

export interface TextPropsCommon extends TextCommon {
    typeElement?: TypeElement;
    textStyle?: TextStyle;
    textTransform?: TextTransform;
    underline?: boolean;
}

export interface TextStyledCommon extends TextCommon, BoxConfig {
    $textStyle?: TextStyle;
    $textTransform?: TextTransform;
    $underline?: boolean;
}

export enum TextTransform {
    UPPERCASE = 'uppercase',
    CAPITALIZE = 'capitalize',
    NONE = 'none',
}

export enum TextStyle {
    NORMAL = 'normal',
    ITALIC = 'italic'
}

export enum TextSize {
    XS = 0.656,
    S = 0.765,
    M = 1,
    L = 1.32,
    XL = 1.875,
    XL2 = 2.3,
    XL3 = 2.625
}

export enum TextWeight {
    REGULAR = 400,
    MEDIUM = 500,
    BOLD = 600,
    SUPER_BOLD = 700
}

export interface TextProps extends TextPropsCommon, PropsWithChildren, BoxConfig {
}