import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';

import Loader from '../loader/loader';

const Agents = lazy(() => import('../../../pages/agents/agents'));
const Properties = lazy(() => import('../../../pages/properties/properties'));

const Router = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <Suspense fallback={<Loader/>}>
                        <Properties/>
                    </Suspense>
                }
            />
            <Route
                path='/agents'
                element={
                    <Suspense fallback={<Loader/>}>
                        <Agents/>
                    </Suspense>
                }
            />
        </Routes>
    )
}

export default Router;