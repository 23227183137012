import {createSlice, Slice} from '@reduxjs/toolkit';

import {initializeCitiesState} from './cities-slice.helper';
import {CitiesResponse} from './cities-slice.types';

export const citiesSlice: Slice<CitiesResponse> = createSlice({
    name: 'cities',
    initialState: {
        ...initializeCitiesState()
    } as CitiesResponse,
    reducers: {
        addCities: (state, action) => {
            state.data = [...action.payload];
        },
    },
});

export const {addCities} = citiesSlice.actions;
