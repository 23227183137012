import styled from 'styled-components';

import {ButtonStyledProps} from './button.types';
import {Colors} from '../../../style/normalize.types';

export const ButtonStyled = styled.button<ButtonStyledProps>`
  padding: 16px 24px;
  background-color: ${({color, theme}) => color ? theme.colors[color as keyof Colors] : theme.colors.blueLight};
  cursor: pointer;
  ${({$full}) => $full ? 'width: 100%;' : ''}
`