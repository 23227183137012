import {createSlice, Slice} from '@reduxjs/toolkit';

import {PropertiesResponse} from './properties-slice.types';
import {initializePropertiesState} from './properties-slice.helper';

export const propertiesSlice: Slice<PropertiesResponse> = createSlice({
    name: 'properties',
    initialState: {
        ...initializePropertiesState()
    } as PropertiesResponse,
    reducers: {
        changePage: (state, action) => {
            if (action.payload <= state.pagination.maxPages) {
                state.pagination.page = action.payload;
            }
        },
        changeSorting: (state, action) => {
            state.pagination.page = 1;
            state.pagination.sortBy = action.payload.sortBy;
            state.pagination.sortDirection = action.payload.sortDirection
        },
        changePropertyType:  (state, action) => {
            state.pagination.page = 1;
            state.filter.type = action.payload;
        },
        changeCities: (state, action) => {
            state.pagination.page = 1;
            if(state.filter.cities.find(cityElement => cityElement.toUpperCase() === action.payload.toUpperCase())){
                state.filter.cities = state.filter.cities.filter(cityElement => cityElement.toUpperCase() !== action.payload.toUpperCase())
            }
            else{
                state.filter.cities = [...state.filter.cities, action.payload];
            }
        },
        changePrice: (state, action) => {
            state.filter.price = action.payload;
        },
        changeSurface: (state, action) => {
            state.filter.surface = action.payload;
        },
        addProperties: (state, action) => {
            state.data = [...action.payload.list];
            state.pagination.maxPages = action.payload.maxPages;
        }
    },
});

export const {addProperties, changePage, changeSorting, changePropertyType, changeCities, changePrice, changeSurface} = propertiesSlice.actions;
