import {PropertiesResponse} from './properties-slice.types';
import {filterInitialConfig} from '../../../config/filter-initial-config';
import {
    PropertiesSortDirection
} from '../../../components/pages/properties/properties-pagination/properties-pagination.types';

export const initializePropertiesState = (): PropertiesResponse => {
    return {
        data: [],
        pagination: {
            limit: 6,
            maxPages: 0,
            page: 1,
            sortBy: 'price',
            sortDirection: PropertiesSortDirection.ASC
        },
        filter: {
            type: null,
            cities: [],
            ...filterInitialConfig
        }
    }
}