import {LinkTop, TopStyled} from './top.styled';
import Container from '../../../../common/container/container';
import Text from '../../../../common/text/text';
import {ReactComponent as EmailIcon} from '../../../../../assets/icon/email-icon.svg'
import {ReactComponent as PhoneIcon} from '../../../../../assets/icon/phone-icon.svg'
import companyDataConfig from '../../../../../config/company-data-config';

const Top = () => {
    return (<Container background={'blueLight'}>
        <TopStyled>
            <Text>{companyDataConfig.address}</Text>
            <LinkTop href={`tel:${companyDataConfig.phone.replaceAll(' ', '')}`}><PhoneIcon/>{companyDataConfig.phone}
            </LinkTop>
            <LinkTop href={`mailto:${companyDataConfig.email}`}><EmailIcon/>{companyDataConfig.email}</LinkTop>
        </TopStyled>
    </Container>)
}

export default Top;