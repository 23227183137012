import {useState} from 'react';

import NavbarMobile from './navbar-mobile/navbar-mobile';
import {BurgerContainer, HeaderMobileStyled, HeaderTop} from './header-mobile.styled';
import {ReactComponent as BarsIcon} from '../../../../assets/icon/bars-icon.svg';
import logo from '../../../../assets/img/logo.png';

const HeaderMobile = () => {
    const [open, setOpen] = useState(false);

    const handleBarsIconClick = () => {
        setOpen(prevState => !prevState);
    }

    const handleModalCloseClick = () => {
        setOpen(false)
    }

    return (<HeaderMobileStyled>
        <HeaderTop>
            <img src={logo} alt={'logo'}/>
            <BurgerContainer onClick={handleBarsIconClick}>
                <BarsIcon/>
            </BurgerContainer>
        </HeaderTop>
        <NavbarMobile open={open} handleModalCloseClick={handleModalCloseClick}/>
    </HeaderMobileStyled>)
}

export default HeaderMobile;