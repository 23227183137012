import {FC} from 'react';

import {ButtonStyled} from './button.styled';
import {ButtonProps} from './button.types';
import Text from '../text/text';
import {TextWeight} from '../text/text.types';

const Button: FC<ButtonProps> = ({children, onClick, full = false, color = 'blueLight'}) => {
    return (<ButtonStyled color={color} $full={full} onClick={onClick}><Text color={'white'}
                                                                             weight={TextWeight.BOLD}>{children}</Text></ButtonStyled>)
}

export default Button;