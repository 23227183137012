import styled from 'styled-components';

export const HeaderMobileStyled = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100%;
`
export const HeaderTop = styled.div`
  position: relative;
  z-index: 1002;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 16px;
  ${({theme}) => `
    border-bottom: 2px solid ${theme.colors.blueLight};
    background: ${theme.colors.white};
  `};
`
export const BurgerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
`