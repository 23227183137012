import {FC} from 'react';

import {TextLinkProps} from './text-link.types';
import {TextLinkStyled} from './text-link.styled';
import Text from '../../../../common/text/text';
import {TextTransform, TextWeight} from '../../../../common/text/text.types';

const TextLink: FC<TextLinkProps> = ({children, type}) => {
    return <TextLinkStyled><Text color={'black'}
                                 textTransform={type === 'static' ? TextTransform.UPPERCASE : TextTransform.NONE}
                                 weight={TextWeight.MEDIUM}>{children}</Text></TextLinkStyled>
}

export default TextLink;