import styled, {keyframes} from 'styled-components';

const moveDown = keyframes`
  0% {
    margin-top: -100px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
`

export const NavbarFixedStyled = styled.div`
  animation-name: ${moveDown};
  animation-duration: 0.5s;
  ${({theme}) => `
    background: ${theme.colors.white};
  `};
`