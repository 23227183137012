import styled from 'styled-components';

export const TopStyled = styled.div`
  display: flex;
  padding: 20px 0;
  gap: 20px;
`

export const LinkTop = styled.a`
  display: flex;
  align-items: center;
  gap: 16px;
`