import {useEffect, useState} from 'react';

const useScrollPosition = (point?: number) => {
    const [scrollY, setScrollY] = useState(0);
    const [belowPoint, setBelowPoint] = useState(false);

    useEffect(() => {
        if (point) {
            setBelowPoint(scrollY >= point);
        }
    }, [point, scrollY])

    useEffect(() => {
        const handleScroll = () => {
            const value = Math.ceil(window.scrollY / 10) * 10
            setScrollY(value);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return {scrollY, belowPoint};
}

export default useScrollPosition;