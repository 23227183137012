import {NavbarStaticStyled} from './navbar-static-styled';
import NavbarCommon from '../navbar-common/navbar-common';
import Container from '../../../common/container/container';

const NavbarStatic = () => {
    return (
        <NavbarStaticStyled>
            <Container background={'transparent'}>
                <NavbarCommon type={'static'}/>
            </Container>
        </NavbarStaticStyled>
    )
}

export default NavbarStatic;