import {combineReducers} from 'redux';
import {Reducer} from '@reduxjs/toolkit';

import {agentsSlice} from './slices/agents/agents-slice';
import {AgentsResponse} from './slices/agents/agents-slice.types';
import {citiesSlice} from './slices/cities/cities-slice';
import {CitiesResponse} from './slices/cities/cities-slice.types';
import {newsSlice} from './slices/news/news-slice';
import {NewsResponse} from './slices/news/news-slice.types';
import {propertiesSlice} from './slices/properties/properties-slice';
import {PropertiesResponse} from './slices/properties/properties-slice.types';
import {propertiesTotalSlice} from './slices/properties-total/properties-total-slice';
import {PropertiesTotalResponse} from './slices/properties-total/properties-total-slice.types';

export interface RootState {
    agents: AgentsResponse;
    properties: PropertiesResponse;
    cities: CitiesResponse;
    news: NewsResponse;
    propertiesTotal: PropertiesTotalResponse;
}

const rootReducer: Reducer<RootState> = combineReducers({
    agents: agentsSlice.reducer,
    properties: propertiesSlice.reducer,
    cities: citiesSlice.reducer,
    news: newsSlice.reducer,
    propertiesTotal: propertiesTotalSlice.reducer
});

export default rootReducer;

