import {NavbarFixedStyled} from './navbar-fixed.styled';
import NavbarCommon from '../navbar-common/navbar-common';
import Container from '../../../common/container/container';

const NavbarFixed = () => {
    return (
        <Container width={'100%'} background={'white'} position={'fixed'} index={1000}
                   shadow={'2px 2px 11px 2px #838383'}
                   coords={{top: '0px'}}>
            <NavbarFixedStyled>
                <NavbarCommon type={'fixed'}/>
            </NavbarFixedStyled>
        </Container>)
}

export default NavbarFixed;