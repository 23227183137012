import {FC} from 'react';

import {TextH1, TextH2, TextH3, TextParagraph, TextSpan} from './text.styled';
import {TextProps, TextSize, TextStyle, TextTransform, TextWeight} from './text.types';

const Text: FC<TextProps> = ({
                                 children,
                                 typeElement = 'span',
                                 color = 'white',
                                 font = 'primary',
                                 weight = TextWeight.REGULAR,
                                 size = TextSize.M,
                                 textStyle = TextStyle.NORMAL,
                                 underline = false,
                                 textTransform = TextTransform.NONE,
                                 ...rest
                             }) => {


    const restProps = {color, font, weight, size, ...rest};

    switch (typeElement) {
        case 'p':
            return <TextParagraph {...restProps} $textStyle={textStyle} $underline={underline}
                                  $textTransform={textTransform}>{children}</TextParagraph>
        case 'h1':
            return <TextH1 {...restProps} $textStyle={textStyle} $underline={underline}
                           $textTransform={textTransform}>{children}</TextH1>
        case 'h2':
            return <TextH2 {...restProps} $textStyle={textStyle} $underline={underline}
                           $textTransform={textTransform}>{children}</TextH2>
        case 'h3':
            return <TextH3 {...restProps} $textStyle={textStyle} $underline={underline}
                           $textTransform={textTransform}>{children}</TextH3>
        default:
            return <TextSpan {...restProps} $textStyle={textStyle} $underline={underline}
                             $textTransform={textTransform}>{children}</TextSpan>
    }
}

export default Text;