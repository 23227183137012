import HeaderDesktop from './header-desktop/header-desktop';
import HeaderMobile from './header-mobile/header-mobile';
import useBreakpoint from '../../../hooks/use-breakpoint';

const Header = () => {
    const {breakpointCheck} = useBreakpoint('mobileLarge');

    return (<header>
        {breakpointCheck ? <HeaderMobile/> : <HeaderDesktop/>}
    </header>)
}

export default Header;