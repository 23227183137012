import Top from './top/top';
import NavbarStatic from '../navbar-static/navbar-static';
import NavbarFixed from '../navbar-fixed/navbar-fixed';
import useScrollPosition from '../../../../hooks/use-scroll-position';

const HeaderDesktop = () => {
    const {belowPoint} = useScrollPosition(150);

    return (<><Top/>{belowPoint ? <NavbarFixed/> : <NavbarStatic/>}</>)
}

export default HeaderDesktop;