import {createSlice, Slice} from '@reduxjs/toolkit';

import {PropertiesTotalResponse} from './properties-total-slice.types';
import {initializePropertiesTotalState} from './properties-total-slice.helper';

export const propertiesTotalSlice: Slice<PropertiesTotalResponse> = createSlice({
    name: 'propertiesTotal',
    initialState: {
        ...initializePropertiesTotalState()
    } as PropertiesTotalResponse,
    reducers: {
        addPropertiesTotal: (state, action) => {
            state.data = [...action.payload];
        },
    },
});

export const {addPropertiesTotal} = propertiesTotalSlice.actions;
