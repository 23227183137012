import InternalLinks from '../utils/internal-links/internal-links';
import {InternalLinksElement} from '../utils/internal-links/internal-links.types';

const linksPageConfig: InternalLinksElement[] = [
    {
        title: 'Home',
        link: null,
    },
    {
        title: 'Properties',
        link: InternalLinks.PROPERTIES,
    },
    {
        title: 'Agents',
        link: InternalLinks.AGENTS,
    },
    {
        title: 'Contact',
        link: null,
    },
    {
        title: 'Send property',
        link: null,
        isSpecial: true
    },

]

export default linksPageConfig;